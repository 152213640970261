import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import LightTooltip from "./LightTooltip";

export default function Footer() {
  return (
    <Box sx={{ bgcolor: "black", color: "white"}}>
      <Stack direction={{ xs: "column", sm: "column", md: "row" }} justifyContent="center" spacing={2}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="flex-start"
          sx={{ p: 2 }}
        >
          <img
            src="/logo.png"
            alt=""
            style={{
              width: 60,
              height: 60,
              background: "white",
              padding: 2,
              borderRadius: 10,
            }}
            className="mb-6 pr-2"
          />
          <IconButton>
            <a
              target="_blank"
              rel="noreferrer"
              class="hover:underline text-white hover:text-orange-500"
              href="https://web.facebook.com/National-Centre-for-Technology-Management-NACETEM-300067793354315"
            >
              <LightTooltip title="NACETEM Facebook handle" placement="top">
                <FacebookIcon size="large" />
              </LightTooltip>
            </a>
          </IconButton>
          <IconButton>
            <a
              target="_blank"
              rel="noreferrer"
              class="hover:underline text-white hover:text-orange-500"
              href=" https://twitter.com/nacetemng1"
            >
              <LightTooltip title="NACETEM Twitter handle" placement="top">
                <TwitterIcon size="large" />
              </LightTooltip>
            </a>
          </IconButton>
          <IconButton>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nacetem.gov.ng/webmail"
              class="hover:underline text-white hover:text-orange-500"
            >
              <LightTooltip title="NACETEM Web Mail" placement="top">
                <EmailIcon size="large" />
              </LightTooltip>
            </a>
          </IconButton>
        </Stack>
        
        <Stack spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
          <h5 class="uppercase text-white mb-2 font-bold">
            News
          </h5>
          <NavLink
            to="/latest-news"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Latest News
          </NavLink>
          <NavLink
            to="/upcoming-events"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Upcoming Events
          </NavLink>
          <NavLink
            to="/past-events"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Past Events
          </NavLink>
                    
        </Stack>
        <Stack spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
          <h5 class="uppercase text-white mb-2 font-bold">
            Research & Capacity Building
          </h5>
          <NavLink
            to="/research"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Research
          </NavLink>
          <NavLink
            to="/capacity-building"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Capacity Building
          </NavLink>
          <NavLink className="text-sm hover:underline text-white hover:text-orange-500"
          to="/leather-home">STI Indicator Dashboard</NavLink>
          
          <NavLink
            to="/psrtest"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            PSR Test
          </NavLink>
        </Stack>
        <Stack spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
          <h5 class="uppercase text-white mb-2 font-bold">Publications</h5>
          <NavLink
            to="/policy-brief"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Policy Brief
          </NavLink>
          <NavLink
            to="/technical-reports"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Technical Reports
          </NavLink>
          
          <NavLink
            to="/newsletter"
            class="text-sm hover:underline text-white hover:text-orange-500"
          >
            Newsletter
          </NavLink>
        </Stack>
        <Stack spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
          <h5 class="uppercase text-white mb-2 font-bold">Agency</h5>
          <NavLink
            to="/about"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            About Us
          </NavLink>
          <NavLink
            to="/contact"
            className="text-sm hover:underline text-white hover:text-orange-500"
          >
            Contact
          </NavLink>
        </Stack>
        <Stack spacing={2} alignItems="flex-start" sx={{ p: 2 }}>
          <h5 class="uppercase text-white mb-2 font-bold">Social</h5>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nacetem.gov.ng/webmail"
              class="text-sm hover:underline  text-white hover:text-orange-500"
            >
              Webmail
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              class="text-sm hover:underline  text-white hover:text-orange-500"
              href="https://web.facebook.com/National-Centre-for-Technology-Management-NACETEM-300067793354315"
            >
              Facebook
            </a>         
            <a
              target="_blank"
              rel="noreferrer"
              class="text-sm hover:underline  text-white hover:text-orange-500"
              href=" https://twitter.com/nacetemng1"
            >
              Twitter
            </a>
        </Stack>
      </Stack>
      <div class="container mx-auto text-center text-white px-6 pt-10 pb-6">
        &copy; 2018 - {new Date().getFullYear()} National Centre for Technology
        Management (NACETEM)
      </div>
    </Box>
  );
}
