import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

export default function PublicationsMenuBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
          color: "maroon",
        }}
        onClick={handleClick}
      >
        Publications <ExpandMoreIcon />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
          color: "white",
          "&:hover":{color:"gold"}
        }}
        onClick={handleClick}
      >
        Publications <ExpandMoreIcon />
      </Box>
      <Menu
        sx={{ overflow: "hidden" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem sx={{ color: "maroon" }} onClick={handleClose}>
          <Link to="/policy-brief">Policy Brief</Link>
        </MenuItem>
        <MenuItem sx={{ color: "maroon" }} onClick={handleClose}>
          <Link to="/technical-reports">Technical Report</Link>
        </MenuItem>
        <MenuItem sx={{ color: "maroon" }} onClick={handleClose}>
          <Link to="/newsletter">Newsletter</Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
